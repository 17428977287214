import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import Tawk from 'vue-tawk';
import VueGtag  from 'vue-gtag';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false
Vue.use(VueMeta)

Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/5e33a027298c395d1ce571be/default'
})

// Vue.use(VueGtag, {
//   config: { id: "G-KS8VZ0MHS1"}
// })

// Vue.use(VueGtag, {
//   config: { id: "AW-594813254"}
// })

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
