<template>
    <v-navigation-drawer v-model="drawer" temporary app @transitionend="closeDrawer" width="100%">
        <v-icon size="35" color="primary" @click="closeDrawer">
            {{mdiClose}}
        </v-icon>
        <v-layout justify-center align-center fill-height column>
            <ul>
                <li v-for="(item, index) in $attrs.items" :key="index"
                             @click="$router.push({ name: item.linkTo, params: { id: item.linkParams }})">
                    {{item.title}}
                </li>
               <li @click="updateSubmenu()" style="position:relative;">
                Features
                <span v-if="showSubmenu"  style="position: absolute; top: 20px; right: 25px;transform: rotate(180deg);">&#8964;</span>
                <span v-else style="position: absolute; top: 12px; right: 25px;">&#8964;</span>
               </li>
                <div v-if="showSubmenu">
                    <li  v-for="(item, index) in itemsNew"
                            :key="index"  @click="$router.push({ name: item.linkTo, params: { id: item.linkParams }})" >
                            {{item.title}}
                    </li>
                </div>

                <li @click="updateSubmenu()" style="position:relative;">
                Who We Serve
                <span v-if="showSubmenu"  style="position: absolute; top: 20px; right: 25px;transform: rotate(180deg);">&#8964;</span>
                <span v-else style="position: absolute; top: 12px; right: 25px;">&#8964;</span>
               </li>
                <div v-if="showSubmenu">
                    <li  v-for="(item, index) in itemswhoweserve"
                            :key="index"  @click="$router.push({ name: item.linkTo, params: { id: item.linkParams }})" >
                            {{item.title}}
                    </li>
                </div>

                <li  @click="$router.push({ name: 'pricing', params: { id: '' }})">Pricing</li>
                <li  @click="$router.push({ name: 'blog', params: { id: '' }})">Blog</li>
                <li  @click="$router.push({ name: 'contact', params: { id: '' }})">Contact</li>
            </ul>

            <div class="btn-container">
                <primary-btn @click="link('https://recordtimeapp.com.au/register')" width="100%"/>
            </div>
            <a @click="signIn()" >Sign In</a>
        </v-layout>
    </v-navigation-drawer>
</template>

<style lang="scss">
    nav.v-navigation-drawer {
        .v-navigation-drawer__content {
            background-color: $black-pearl;
            button.v-icon {
                position: absolute;
                right: 10px;
                top: 10px
            }

            div.layout {
                ul {
                    width: 100%;
                    list-style: none;
                    padding: 0;

                    li {
                        text-align: center;
                        opacity: 0.6;
                        font-size: 16px;
                        color: #ffffff;
                        padding: 16px 0;
                        margin: 0 16px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                        &:hover,
                        &:focus {
                            opacity: 1;
                        }
                    }
                }

                .btn-container {
                    width: calc(100% - 32px);
                    text-align: center;
                }

                a {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    letter-spacing: 1px;
                    color: #ffffff;
                    padding-top: 16px;
                    border-bottom: 1px solid #ffffff;
                }
            }
        }
    }
</style>

<script>
    import { mdiClose } from '@mdi/js';
    import PrimaryBtn from '@/components/PrimaryBtn.vue';

    export default {
        components: {
            PrimaryBtn
        },
        data() {
            return {
              drawer: true,
              mdiClose: mdiClose,
              showSubmenu: false,
              itemsNew: [
                { title: 'Digital Docketing', linkTo: 'digitalDocketing' },
                { title: 'Pre-start Checklist', linkTo: 'preStartChecklist'},
                { title: 'Proof of Work', linkTo: 'proofOfWork' },
                { title: 'Staff Time Tracking', linkTo: 'staffTimeTracking' },
                { title: 'Custom Invoicing', linkTo: 'customInvoicing' },
                { title: 'Job Scheduling', linkTo: 'jobScheduling' },
                { title: 'Jobsite Management', linkTo: 'jobsiteManagement' },
                { title: 'Remote Approval', linkTo: 'remoteApproval' },
                { title: 'Plant Management', linkTo: 'plantManagement' },
                ],
                itemswhoweserve: [
                { title: 'Heavy Civil', linkTo: 'heavyCivil' },
                { title: 'Cleaning Business', linkTo: 'cleaningBusiness' },
                { title: 'Concrete', linkTo: 'concrete' },
                { title: 'Crane And Rigging', linkTo: 'craneAndRigging' },
                { title: 'Delivery Business', linkTo: 'deliveryBusiness' },
                { title: 'Electrical Contractor', linkTo: 'electricalContractor' },
                { title: 'Excavation And Earthmoving', linkTo: 'excavationAndEarthmoving' },
                { title: 'Plumbing Business', linkTo: 'plumbingBusiness' },
                { title: 'Geotechnical Engineers', linkTo: 'geotechnicalEngineers' },
                ],
            }
        },
        methods: {
            closeDrawer() {
                this.$emit('showDrawer', false);
            },
            signIn(){
                window.open('https://recordtimeapp.com.au/login', '_blank');
            },
            link(value){
                window.open(value, '_blank');
            },
            updateSubmenu(){
                this.showSubmenu = (this.showSubmenu)?false:true;
            }
        }
    }
</script>

