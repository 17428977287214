<template>
  <div id="app">
    <v-app>

      <nav-drawer :items="navItems" v-if="showDrawer" @showDrawer="transitionend" />

      <!-- App bar -->
      <v-app-bar app color="#FFFFFF" tile height="70px" fixed>
        <v-container class="d-flex align-center">
          <v-app-bar-nav-icon @click="showDrawer = true" v-if="$vuetify.breakpoint.mdAndDown">
            <slot>
              <v-icon size="32" color="primary">{{mdiText}}</v-icon>
            </slot>
          </v-app-bar-nav-icon>
          <a @click="$router.push({ name: 'home'})">
            <v-img :src="`${publicPath}assets/record-time.webp`" alt="logo record time" :width="$vuetify.breakpoint.mdAndDown ? 100 : 160"/>
          </a>

          <div class="hidden-md-and-down ml-md-14">
            <a v-for="(item, index) in navItems" :key="index" class="nav-link pr-md-8"
               :class="item.linkTo === $route.name ? 'route-active': ''"
                @click="$router.push({ name: item.linkTo, params: { id: item.linkParams }})">
              {{item.title}}
            </a>
          </div>

          <div class="hidden-md-and-down">
            <template>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                    class="nav-link pr-md-8"
                      v-bind="attrs"
                      v-on="on"
                    >
                    Features
                    </a>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <v-list-item-title>
                        <a  class="nav-link pr-md-8"  @click="$router.push({ name: item.linkTo, params: { id: item.linkParams }})">{{ item.title }}</a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
           
          </div>

          <div class="hidden-md-and-down">
            <template>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                    class="nav-link pr-md-8"
                      v-bind="attrs"
                      v-on="on"
                    >
                    Who We Serve
                    </a>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in itemswhoweserve"
                      :key="index"
                    >
                      <v-list-item-title>
                        <a  class="nav-link pr-md-8"  @click="$router.push({ name: item.linkTo, params: { id: item.linkParams }})">{{ item.title }}</a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
           
          </div>
          <a  class="nav-link hidden-md-and-down pr-md-8"
              :class="'pricing' === $route.name ? 'route-active': ''"
              @click="$router.push({ name: 'pricing', params: { id: '' }})">Pricing
          </a>
          <a  class="nav-link hidden-md-and-down pr-md-8"
              :class="'blog' === $route.name ? 'route-active': ''"
              @click="$router.push({ name: 'blog', params: { id: '' }})">Blog
          </a>
          <a  class="nav-link hidden-md-and-down"
                :class="'contact' === $route.name ? 'route-active': ''"
                @click="$router.push({ name: 'contact', params: { id: '' }})">Contact
            </a>

          <v-spacer></v-spacer>

          <a @click="signIn()" class="sign-in-link mr-8 hidden-xs-only">
            Sign In
          </a>
          <primary-btn @click="link('https://recordtimeapp.com.au/register')" />
        </v-container>
      </v-app-bar>
      <!-- App bar -->


      <!-- Router content -->
      <v-main style="min-height: 50%;">
        <router-view/>
      </v-main>
      <!-- Router content -->

      <!-- Footer -->
      <footer-comp :items="navItems"/>
      <!-- Footer -->

    </v-app>
  </div>
</template>

<style lang="scss">
  /*@import "scss/variables";*/
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    header.v-app-bar {
      z-index: 40;

      @media only screen and (max-width: 1264px) {
        padding-top: 0;
        div.v-toolbar__content {
          padding: 0;
        }
      }
    }

    .v-navigation-drawer {
      z-index: 50;
    }

    .nav-link {
      cursor: pointer;
      color: $black-pearl;
      opacity: 0.4;

      &.route-active {
        font-weight: bold;
        opacity: unset;
        color: $faux-dark-turquoise;
      }

      &:hover {
        opacity: 1;
      }
    }

    .sign-in-link {
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1px;
      color: $black-pearl;

      &:hover {
        border-bottom: 2px solid $black-pearl;
      }
    }
  }
</style>

<script>
  import NavDrawer from '@/components/NavDrawer.vue';
  import PrimaryBtn from '@/components/PrimaryBtn.vue';
  import FooterComp from '@/components/Footer.vue';
  import { mdiText } from '@mdi/js';

 

  export default {
    components: {
      NavDrawer, PrimaryBtn, FooterComp
    },
    data() {
      return {
        publicPath: process.env.BASE_URL,
        showDrawer: false,
        mdiText: mdiText,
        navItems: [
          { title: "Home", isMainNavItem: true, linkTo: 'home'},
          { title: "About Us", isMainNavItem: true, linkTo: 'about'},
          // { title: "Pricing", isMainNavItem: true, linkTo: 'pricing'},
          // { title: "Blog", isMainNavItem: true, linkTo: 'blog'},
          // { title: "FAQs", isMainNavItem: true, linkTo: 'faq'},
          // { title: "Contact", isMainNavItem: true, linkTo: 'contact'}
        ],
        items: [
          { title: 'Digital Docketing', linkTo: 'digitalDocketing' },
          { title: 'Pre-start Checklist', linkTo: 'preStartChecklist'},
          { title: 'Proof of Work', linkTo: 'proofOfWork' },
          { title: 'Staff Time Tracking', linkTo: 'staffTimeTracking' },
          { title: 'Custom Invoicing', linkTo: 'customInvoicing' },
          { title: 'Job Scheduling', linkTo: 'jobScheduling' },
          { title: 'Jobsite Management', linkTo: 'jobsiteManagement' },
          { title: 'Remote Approval', linkTo: 'remoteApproval' },
          { title: 'Plant Management', linkTo: 'plantManagement' },
        ],
        itemswhoweserve: [
          { title: 'Heavy Civil', linkTo: 'heavyCivil' },
          { title: 'Cleaning Business', linkTo: 'cleaningBusiness'},
          { title: 'Concrete', linkTo: 'concrete' },
          { title: 'Crane And Rigging', linkTo: 'craneAndRigging' },
          { title: 'Delivery Business', linkTo: 'deliveryBusiness' },
          { title: 'Electrical Contractor', linkTo: 'electricalContractor' },
          { title: 'Excavation And Earthmoving', linkTo: 'excavationAndEarthmoving' },
          { title: 'Plumbing Business', linkTo: 'plumbingBusiness' },
          { title: 'Geotechnical Engineers', linkTo: 'geotechnicalEngineers' },
        ],
      }
    },
    methods: {
      transitionend() {
        this.showDrawer = false;
      },
      signIn(){
        window.open('https://recordtimeapp.com.au/login', '_blank');
      },
      link(value){
        window.open(value, '_blank');
      }
    }
  }
</script>
