import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/pricing',
    name: 'pricing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue')
  },
  {
    path: '/blog/:id',
    name: 'blogDetail',
    meta: {
      title: 'Blog',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogDetail.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/license-agreement',
    name: 'license',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/LicenseAgreement.vue')
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/TermsOfUse.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/digital-docketing',
    name: 'digitalDocketing',
    component: () => import('../views/Features/DigitalDocketing.vue')
  },
  {
    path: '/pre-start-checklist',
    name: 'preStartChecklist',
    component: () => import('../views/Features/PreStartChecklist.vue')
  },
  {
    path: '/proof-of-work-completion',
    name: 'proofOfWork',
    component: () => import('../views/Features/ProofOfWork.vue')
  },
  {
    path: '/field-staff-real-time-tracking',
    name: 'staffTimeTracking',
    component: () => import('../views/Features/StaffTimeTracking.vue')
  },

  {
    path: '/custom-invoice-book',
    name: 'customInvoicing',
    component: () => import('../views/Features/CustomInvoicing.vue')
  },

  {
    path: '/job-scheduling-software',
    name: 'jobScheduling',
    component: () => import('../views/Features/JobScheduling.vue')
  },
  {
  path: '/jobsite-management-software',
  name: 'jobsiteManagement',
  component: () => import('../views/Features/JobsiteManagement.vue')
  },
  {
    path: '/remote-approval-of-site-work',
    name: 'remoteApproval',
    component: () => import('../views/Features/RemoteApproval.vue')
  },
  {
    path: '/plant-and-equipment-management-software',
    name: 'plantManagement',
    component: () => import('../views/Features/PlantManagement.vue')
  },
  {
    path: '/heavy-civil-software',
    name: 'heavyCivil',
    component: () => import('../views/WhoWeServe/HeavyCivil.vue')
  },
  {
    path: '/cleaning-business-software-australia',
    name: 'cleaningBusiness',
    component: () => import('../views/WhoWeServe/CleaningBusiness.vue')
  },
  {
    path: '/digital-docketing-app-for-concreting',
    name: 'concrete',
    component: () => import('../views/WhoWeServe/Concrete.vue')
  },
  {
    path: '/crane-and-rigging-contractor-software',
    name: 'craneAndRigging',
    component: () => import('../views/WhoWeServe/CraneAndRigging.vue')
  },
  {
    path: '/proof-of-delivery-app',
    name: 'deliveryBusiness',
    component: () => import('../views/WhoWeServe/DeliveryBusiness.vue')
  },
  {
    path: '/electrical-contractor-software',
    name: 'electricalContractor',
    component: () => import('../views/WhoWeServe/ElectricalContractor.vue')
  },
  {
    path: '/excavation-and-earthmoving-contractor-software',
    name: 'excavationAndEarthmoving',
    component: () => import('../views/WhoWeServe/ExcavationAndEarthmoving.vue')
  },
  {
    path: '/plumbing-contractor-software',
    name: 'plumbingBusiness',
    component: () => import('../views/WhoWeServe/PlumbingBusiness.vue')
  },
  {
    path: '/geotechnical-engineer-app',
    name: 'geotechnicalEngineers',
    component: () => import('../views/WhoWeServe/GeotechnicalEngineers.vue')
  },

  {
    path: '/types-of-digital-forms-you-can-create-using-record-times-docket-book',
    name: 'yourUltimateDocket',
    component: () => import('../views/Blogs/YourUltimateDocket.vue')
  },

  {
    path: '/vehicle-safety-checks-and-list-of-pre-start-checklist',
    name: 'typesOfPreStartChecklist',
    component: () => import('../views/Blogs/TypesOfPreStartChecklist.vue')  
  },
  {
    path: '/record-time-for-plumbing-business',
    name: 'plumbingBusiness',
    component: () => import('../views/Blogs/PlumbingBusiness.vue')  
  },
  {
    path: '/jobsite-manager',
    name: 'jobsiteManager',
    component: () => import('../views/Blogs/JobsiteManager.vue')  
  },
  {
    path: '/document-management-for-businesses',
    name: 'DocumentManagement',
    component: () => import('../views/Blogs/DocumentManagement.vue')  
  },
  {
    path: '/help',
    name: 'help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HowTo.vue')
  },
  {
    path: '/help/:id',
    name: 'helpDetail',
    meta: {
      title: 'Help',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HelpDetail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior () {
    // return desired position
    return { x: 0, y: 0 };
  }
});

export default router
